<template>
  <div class="redem-body">
    <div class="score">获得积分： <strong>{{detail.score}}</strong></div>
    <div v-if="detail.score >= 50">
      <div class="redem-title">
        <div>恭喜你获得体验资格!</div>
        <div>Congratulations on your qualification for the experience!</div>
      </div>

      <div class="redem-subtitle">
        <div>请选择 VR 项目</div>
        <div>Please choose a VR item</div>
      </div>
    </div>
    <div v-else>
      <div class="redem-title">
        <div>很遗憾，积分不足，无法兑换!</div>
        <div>Unfortunately, there are not enough points to redeem!</div>
      </div>
    </div>
    <div class="redem-list">
      <div class="mvbutton redem-footer continue-btn" @click="goques">
        <div>放弃体验，继续答题（有机会获得博物馆实物礼品）</div>
        <div>Give up the experience and continue to answer the questions to  achieve a museum's physical gift</div>
      </div>

      <div class="redem-item" v-for="(item,index) in list" :key="index"  @click="pick(item,index)" 
        :class="{ active: item.actived, disabled: (item.lastNum == 0?1:0 || detail.score < 50) }">
        <div class="redem-left">
          <div>{{item.title}}</div>
          <div class="title_en">{{item.title_en}}</div>
        </div>
        <div class="redem-right">
          <div>剩余{{item.lastNum}}个名额：</div>
          <div>{{item.lastNum}} Spots Open</div>
        </div>
      </div>
      <div class="notice" v-if="hasStock">体验数量有限，先到先得，领完即止！</div>
      <div class="notice" v-else>当前 VR 体验项目暂停兑换<br> VR items are currently unavailable</div>

      <template class="test" v-if="detail.score*1 >= 50">
        <div class="mvbutton" @click="confirmGoods" style="margin-bottom: 20px;" v-if="picked">
          <div>确认并参与用户体验调研</div>
          <div>Participate in user experience research</div>
        </div>
        <div class="mvbutton disabled" style="margin-bottom: 20px;" v-else>
          <div>确认并参与用户体验调研</div>
          <div>Participate in user experience research</div>
        </div>
      </template>
    </div>
    <IDVerify :promise="verifyPromise" />
    <OpenQuestion :promise="openQuestionPromise" />
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import api from "@/api/api";
import router from "@/router";
import request from '@/api/request'
import wx from "weixin-js-sdk"
import OpenQuestion from '@/components/OpenQuestion'
import IDVerify from '@/components/IDVerify'
import { mapState, mapGetters, mapMutations } from "vuex"

export default {
  name: "Redemption",
  components: {
    OpenQuestion,
    IDVerify,
  },
  data(){
    return {
      codes:'',
      list:[],
      detail: {},
      verifyPromise: {
        promise: null,
        resolve: null,
        reject : null,
      },
      openQuestionPromise: {
        promise: null,
        resolve: null,
        reject: null,
      },
    }
  },
  computed: {
    ...mapState(["openQuestion", "idVerify"]),
    picked() {
      return this.list.filter(v => v.actived).length > 0
    },
    hasStock() {
      let item = this.list.find(i => i.lastNum > 0)
      return item !== undefined
    }
  },
  methods: {
    ...mapMutations([
      'setOpenQuestion', 
      'setOpenQuestionVisiable',
      'setIDVerifyVisiable',
      'setIDVerifyCode',
    ]),
    verifyTimeKey() {
      return (new Date).toISOString().substr(0, 10)
    },
    setVerifyTime(n) {
      return localStorage.setItem(this.verifyTimeKey(), n)
    },
    getVerifyTime() {
      return localStorage.getItem(this.verifyTimeKey(), 0) * 1
    },
    goques(){
      if (this.detail.score >= 50) {
        this.$router.push({path:'/question',query:{aid:941,skip:1}});
      } else {
        this.$router.push({path:'/question',query:{aid:941}});
      }
    },
    getData()
    {
      api.detail(this.acid).then((d) => {
        if(d.data.status == 200)
        {
          if (d.data.data.hasjack) {
            this.$router.push({
              name: 'Redemlist',
            })
            return
          }
          d.data.data.list.forEach((item)=>{
            item.actived = false
          })
          this.list = d.data.data.list
          this.activity = d.data.data.activity
          this.detail = d.data.data
          // this.detail.score = 1111 /* 调试得分 */
        }
      })
    },
    goWx(wxpath, routeObject)
    {
      try{
        let r = wx.miniProgram.reLaunch({
          url: wxpath
        })
        console.log(r)
      }catch (e)
      {
        this.$router.push(routeObject)
      }
    },
    verifyReserve() {
      if (this.idVerify.verified) {
        return true
      }
      this.setIDVerifyVisiable(true)
      this.setIDVerifyCode('')
      return this.verifyPromise.promise
    },
    verifyOpenQuestion() {
      if (this.openQuestion.answered) { // 今日已答过
        return true
      }
      if (this.openQuestion.openQuestionAnswered) {
        return true
      }
      this.setOpenQuestionVisiable(true)
      return this.openQuestionPromise.promise
    },
    async confirmGoods(){
      if (! await this.verifyReserve()) {
        return
      }
      if (! await this.verifyOpenQuestion()) {
        return
      }
      let item = this.list.find(i => i.actived)
      if (!item) {
        this.$message({
          message: "请选择体验项目",
          type: 'warning'
        })
      } else {
        request.get('customize/sports-museum/drawonce', {
          params: {
            acid: item.acid,
            xid: item.id,
          }
        }).then(r => {
          if (r.data.status == 203) {
            this.$message({
              message: "你已经领过了",
              type: 'warning'
            })
          } else if (r.data.status == 245) {
            this.$message({
              message: "已经领完了",
              type: 'warning'
            })
          } else {
            if (r.data.status != 200) {
              this.$message({
                message: r.data.message,
                type: 'warning'
              })
              return 
            }
            this.goWx('/pages/exchange/index', {
              name: 'Reservcert',
              query: {
                jid: r.data.data.id
              }
            })
          }
        })
      }
    },
    pick(item,index) {
      if(item.lastNum==0)
        return;
      this.list.forEach((item,io)=>{
        if(io===index*1){
          item.actived = true
        }else{
          item.actived = false
        }

      })
    },
  },

  mounted(){
    this.acid = this.$route.query.acid||934
    this.getData()
    api.question(942).then(r => {
      if (!r.data.data.qtoday) {
        this.setOpenQuestion(r.data.data.questions[0])
        this.openQuestionPromise.promise = new Promise((resolve, reject) => {
          this.openQuestionPromise.resolve = resolve
          this.openQuestionPromise.reject = reject
        })
      } else {
        this.setOpenQuestion({
          answered: true
        })
      }
    }).catch(e => {
      this.$message('加载开放问题失败')
    })
    this.verifyPromise.promise = new Promise((resolve, reject) => {
      this.verifyPromise.resolve = resolve
      this.verifyPromise.reject = reject
    })
  },
  watch: {
  }
}
</script>

<style scoped lang="less">

.redem-body{
  /*background-color: rgba(214, 201, 172, 1);*/
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  margin-top: 30px;
}

.redem-subtitle
{
  font-size: 16px;
  margin-top: 30px;
}
.redem-body .redem-title>div:first-child{
  font-size: 24px;
  font-weight: 400;
}
.redem-body .redem-title>div:nth-child(2){
  font-size: 24px;
}
.redem-body .redem-list{
  box-sizing: border-box;
  padding: 10px;
  margin-top: 20px;
  width: 100%;
}
.redem-body .redem-list .redem-item{
  width: 100%;
  height: 54px;
  background-color: rgba(187, 138, 76, 1);
  border-radius: 4px 0 4px 0;
  box-shadow: 5px 5px 5px rgb(149 104 47);
  margin-bottom: 20px;
  display: flex;
  font-weight: 400;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 10px;
}
.redem-body .redem-list .redem-item .redem-left{
  font-size: 16px;
  text-align: left;
}

.redem-body .redem-list .disabled{
  background-color: rgba(215, 215, 215, 1);
  box-shadow: 5px 5px 5px rgb(150, 150, 150);
}

.redem-body .redem-list .redem-item .redem-left .title_en{
  font-size: 14px;
  text-align: left;
}

.redem-body .redem-list .redem-item .redem-right{
  font-size: 12px;
}
.redem-body .redem-footer{
  width: 100%;
  height: 73px;
}
.redem-body .redem-list .notice{
  font-size: 16px;
  color: #242020;
  text-align: center;
  padding-bottom: 20px;
}
.redem-body .redem-list .active{
  background-color: rgba(149, 104, 47, 1);
}
.redem-body .redem-list .disabled{
  background-color: rgba(215, 215, 215, 1);
}

.score {
  height: 50px;
  // color: rgba(149, 104, 47, 1);
  font-size: 20px;
  line-height: 2;
}
.score strong {
  // font-weight: 100;
  font-size: 24px;
}

.continue-btn {
  margin-bottom: 20px;
}
</style>
