// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/input4.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".code-input-wrap[data-v-17abf35c]{position:relative}.code-input[data-v-17abf35c]{position:relative;z-index:1;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:12.5vw;margin:.521vw -2.292vw 0 .417vw;letter-spacing:2.292vw;font-size:1.563vw;padding:0 0 0 .833vw}.code-input[data-v-17abf35c],.real-code-input[data-v-17abf35c]{height:3.281vw;line-height:3.125vw;width:12.5vw;display:block;border:none;outline:none;overflow:hidden}.real-code-input[data-v-17abf35c]{z-index:100;position:absolute;top:0;left:-1.302vw;opacity:0;background:#ffe;margin:0;padding:0 0 0 1.042vw}", ""]);
// Exports
module.exports = exports;
